.about_section {
  width: 90%;
  margin: auto;
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
  gap: 1rem;
  margin-top: 2rem;
}
.about_section > div {
  flex: 1;
}
.about_section > div img {
  width: 100%;
  object-fit: contain;
}
.about_section > div:nth-child(1) > div {
  font-size: 1rem;
  flex: 2;
  line-height: 2rem;
  margin-top: 1rem;
}
