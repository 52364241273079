.footer_container {
  padding: 2rem;
  color: var(--white-color);
  background-color: var(--footer-bg);
}
.footer_container > div:nth-child(1) {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.footer_container > div:nth-child(3) {
  text-align: center;
  margin-top: 1rem;
  font-weight: 600;
}
.footer_container > div:nth-child(2) img {
  width: 100%;
  object-fit: contain;
}
.footer_container > div:nth-child(2) {
  display: flex;
  justify-content: space-evenly;

  align-items: center;
}

@media screen and (min-width: 280px) and (max-width: 600px) {
  .navbar_container {
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  .footer_container > div:nth-child(2) {
    flex-direction: column;
  }
}
