.content_list_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 1.5rem;
  gap: 1rem;
  flex-wrap: wrap;
}
.content_list_container > a {
  text-decoration: none;
}
.content_item {
  transition: 500ms ease-in-out;
  color: var(--white-color);
}
.content_item:hover {
  box-shadow: rgba(255, 255, 255, 0.05) 0px 6px 24px 0px,
    rgba(255, 255, 255, 0.08) 0px 0px 0px 1px;
}
.content_item > div > img {
  width: 100%;
  object-fit: contain;
}
.favourrites_container {
  padding: 1rem;
}
.favourrites_container h2 {
  margin-bottom: 1.5rem;
  color: var(--bg-color-2);
  text-decoration: underline;
  text-transform: uppercase;
}
.content_item_header h5,
.content_item_header p {
  margin-bottom: 0;
}
.content_item_header {
  display: flex;
  color: var(--white-color);
  justify-content: space-between;
}
.genre_list {
  color: var(--white-color);
}

.favourrites_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
}
.content_list_container {
  width: 90%;
  margin: auto;
}
/* media queries for mobile screen */
.carousel_slides {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  background-color: var(--white-color);
  border-radius: 1rem;
  margin: 10px;
  padding: 10px;
  width: 90% !important;
}
.carousel_slides img {
  height: 250px;
  width: 100%;
  border-radius: 1rem;
  object-fit: cover;
  margin: auto;
  margin-bottom: 1rem;
}
.product_carousel_box {
  width: 90%;
  margin: auto;
  gap: 20px;
  position: relative;
}
.product_carousel_box span {
  font-weight: 500;
  color: var(--bg-color-1);
  position: absolute;
  padding-left: 30px;
  top: 30px;
  z-index: 1;
}
.carousel_slides p {
  color: var(--bg-color-2);
  line-height: 1.5rem;
}
.carousel_slides:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.all_catogries_container > h2 {
  position: relative;
  padding: 0;
  margin: 0;
  margin-top: 1rem;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 2rem;
  color: var(--bg-color-2);
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

/* === HEADING STYLE #1 === */
.all_catogries_container > h2 {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 5px;
}
.all_catogries_container > h2:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: var(--bg-color-1);
}
.all_catogries_container > h2:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 15px;
  left: 50%;
  margin-left: -50px;
  background-color: var(--bg-color-1);
}
.category_container {
  margin-top: 1rem;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}
@media screen and (min-width: 270px) and (max-width: 480px) {
  .content_list_container {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* media queries for tabs screen */

@media screen and (min-width: 480px) and (max-width: 620px) {
  .content_list_container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 480px) and (max-width: 867px) {
}
