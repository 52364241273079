.navbar_container {
  padding: 0px 20px;
  background: transparent;
  justify-content: space-evenly;
  border-bottom: 2px solid var(--bg-color-1);
}
.navbar_container > div {
  flex: 1;
}
.navbar_container,
.navbar_container > div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
}
.navbar_container > div img {
  height: 60px;
  object-fit: contain;
}
.navbar_container > div > input {
  padding: 10px;
}
.profile_section {
  display: flex;
  justify-content: flex-end !important;
  padding-right: 2rem;
}
.navbar_img {
  display: flex;
  justify-content: flex-start !important;
}
/* CSS */
.navbar_container > div > button {
  background: var(--bg-color-1);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  color: #fff;

  padding: 10px;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 1rem;
}

.navbar_container > div > button :focus {
  box-shadow: var(--bg-color-1) 0 0 0 1.5px inset,
    rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
    var(--bg-color-1) 0 -3px 0 inset;
}

.navbar_container > div > button:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, var(--bg-color-1) 0 -3px 0 inset;
  transform: translateY(-2px);
}

.navbar_container > div > button:active {
  box-shadow: var(--bg-color-1) 0 3px 7px inset;
  transform: translateY(2px);
}

.nav_item_container {
  background-color: var(--bg-color-1);
  padding: 15px;
}
.nav_item_container > span {
  padding: 20px;
  font-weight: 500;
  cursor: pointer;
  transition: 500ms ease;
}
.nav_item_container > span:hover {
  color: var(--white-color);
}

.nav_items_box span {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--bg-color-1);
  transition: 400ms ease-in;
}
.nav_items_box span:hover {
  color: var(--bg-color-1);
  font-size: 1.4rem;
  cursor: pointer;
}
.profile_section img {
  height: 50px !important;
  object-fit: contain;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown > div:nth-child(1) {
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
}

.dropdown > div:nth-child(1):hover {
  background-color: #ddd;
}

.dropdown_options {
  display: none;
  position: absolute;
  overflow: auto;
  width: 200px !important;
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4);
}

.dropdown:hover .dropdown_options {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.dropdown_options {
  display: none;
  color: #000000;
  text-decoration: none;
  z-index: 10;
  padding: 1rem;
}
.dropdown_options > a:hover {
  color: var(--bg-color-1);
}
.dropdown_options > a {
  color: var(--bg-color-2);
  transition: 500ms ease-in;
}
.dropdown_options:hover {
  color: #0a0a23;
  border-radius: 5px;
}

@media screen and (min-width: 280px) and (max-width: 600px) {
  .navbar_container > div input {
    display: none;
  }
  .navbar_container > div > img {
    width: 100%;
  }
}
