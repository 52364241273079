.product_des_container {
  position: relative;
}
.product_des_container > div:nth-child(1) {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  color: var(--bg-color-2);
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.product_des_container > div:nth-child(1) p {
  font-size: 1.5rem;
  width: 80%;
}
.product_des_container > div:nth-child(1) span {
  color: red;
}
.order_btn {
  background: var(--bg-color-1);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  color: #fff;

  padding: 10px;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 1rem;
}

.order_btn:focus {
  box-shadow: var(--bg-color-1) 0 0 0 1.5px inset,
    rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
    var(--bg-color-1) 0 -3px 0 inset;
}

.order_btn:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, var(--bg-color-1) 0 -3px 0 inset;
  transform: translateY(-2px);
}
.order_btn:active {
  box-shadow: var(--bg-color-1) 0 3px 7px inset;
  transform: translateY(2px);
}

.product_desc_nav {
  background: var(--bg-color-3);
  padding: 1.5rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: sticky;
  top: 0;
}
.product_desc_nav a {
  color: var(--bg-color-2);
  font-weight: 500;
}
#details {
  margin-top: 2rem;
}
