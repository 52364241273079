.technical_documentation {
  width: 80%;
  margin: auto;
  margin-top: 3rem;
}

.technical_documentation > h2 {
  margin-bottom: 1rem;
}
.technical_documentation > div {
  line-height: 2rem;
}
