.carousel_slides {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  background-color: var(--white-color);

  margin: 10px;
  padding: 10px;
  width: 90% !important;
}
.carousel_slides img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  margin: auto;
  margin-bottom: 1rem;
}
.product_carousel_box {
  width: 90%;
  margin: auto;
  gap: 20px;
  position: relative;
}
.product_carousel_box span {
  font-weight: 500;
  color: var(--bg-color-1);
  position: absolute;
  padding-left: 30px;
  top: 30px;
  z-index: 1;
}
.carousel_slides p {
  color: var(--bg-color-2);
  line-height: 1.5rem;
}
.carousel_slides:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
