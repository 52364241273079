@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500;600&display=swap");
body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --bg-color-1: #2ba3f5;
  --white-color: #fff;
  --bg-color-2: #555555;
  --bg-color-3: #f7f7f7;
  --footer-bg: #333333;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
}

body {
  font-size: 16px;
  font-family: "poppins", sans-serif;
}
a {
  text-decoration: none;
}
html {
  scroll-behavior: smooth;
}
