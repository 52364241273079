.home_item_container {
  background-color: var(--bg-color-2);
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_item_container > span {
  padding: 20px;
  font-weight: 400;
  cursor: pointer;
  transition: 500ms ease;
  color: var(--white-color);
}
.home_item_container > span:hover {
  font-weight: 500;
}
.passion_container {
  background-color: var(--bg-color-1);
  color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
}
.passion_container > div:nth-child(1) {
  flex: 2;
  text-align: center;
  font-size: 1.4rem;
}

.passion_container > div:nth-child(2) {
  flex: 4;
  line-height: 1.5rem;
}
.passion_container > div:nth-child(2) > div {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  gap: 10px;
}
.new_products,
.commitment_box {
  background-color: var(--bg-color-3);
  color: var(--bg-color-2);
  padding: 2rem;
}
.new_products {
  padding-top: 3rem;
}
.new_products > h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}
.commitment_box {
  padding: 4rem;
}
.commitment_box > div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 2rem;
  margin-top: 2rem;
}
.commitment_box > div span {
  color: var(--bg-color-1);
  font-size: 2rem;
  font-weight: 600;
}
.products_range {
  text-align: center;
}
.products_range > div {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.products_range > div > a {
  text-decoration: none;
  color: var(--bg-color-2);
  display: flex;
  gap: 1rem;
}
.products_range > div span {
  border: 1px solid;
}
.products_range > div div {
  font-weight: 500;
  font-size: 1.4rem;
  flex: 1;
  cursor: pointer;
  transition: 500ms ease-in;
}
.products_range > div div:hover {
  color: var(--bg-color-1);
}
.products_range > div div:active {
  color: var(--bg-color-2);
}

@media screen and (min-width: 280px) and (max-width: 600px) {
  .navbar_container {
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  .commitment_box {
    padding: 1rem;
  }
  .commitment_box > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-wrap: wrap;
    gap: 1.5rem;
    padding: 1rem;
    margin-top: 2rem;
  }
  .passion_container {
    flex-direction: column;
  }
}
