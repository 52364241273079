.carousel_container {
  width: 100%;
  margin: auto;
}

.carousel_container img {
  object-fit: contain;
  width: 100%;
}
.carousel_slide_item {
  position: relative;
}
.carousel_slide_item > div:nth-child(1) {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #000, transparent);
  color: var(--white-color);
  padding: 30px;
}

@media screen and (min-width: 280px) and (max-width: 600px) {
  .carousel_container img {
    object-fit: cover;
    width: 100%;
    height: 50vh;
  }
}
