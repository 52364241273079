.contact_container {
  width: 80%;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.contact_container > h1 {
  text-align: center;
  margin-bottom: 1rem;
  color: var(--bg-color-2);
}
.contact_container > form {
  display: flex;
  width: 40%;
  margin: auto;
  flex-direction: column;
  gap: 1rem;
}
.contact_container > form > div {
  display: flex;
  flex-direction: column;
}
.contact_container > form > div > label {
  color: var(--bg-color-2);
  font-size: 1.3rem;
}
.contact_container > form > div > input,
.contact_container > form > div > button,
.contact_container > form > div > textarea {
  padding: 0.3rem;
  border-radius: 0.3rem;
  font-size: 1.2rem;
}

.no_spin::-webkit-inner-spin-button,
.no_spin::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.no_spin {
  -moz-appearance: textfield;
}

.contact_container > form > div > button {
  background: var(--bg-color-1);
  border: 1px solid var(--bg-color-1);
  color: #fff;
}
.contact_container > form > div > button:active {
  background: #fff;
  color: var(--bg-color-1);
  border: 1px solid var(--bg-color-1);
}

button {
  cursor: pointer;
}
