.video_container {
  width: 100%;
  position: relative;
}
.video_container > video {
  width: 100%;
  max-height: 80vh;
  object-fit: cover;
}
.home_text_box {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  color: var(--white-color);
  width: calc(100% - 4rem);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 4rem;
  gap: 2rem;
  background: rgba(0, 0, 0, 0.1);
}
.home_text_box h1 {
  font-size: 4rem;
}
.home_text_box h2 {
  width: 80%;
  text-align: center;
}
