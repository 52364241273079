.product_detials_item {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid var(--bg-color-3);
}
.product_detials_item > span {
  cursor: pointer;
  padding: 1rem;
}
.products_details {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 1rem;
}
.products_details > div {
  flex: 1;
}
.products_details > div > img {
  width: 100%;
  object-fit: contain;
}
.product_detials_container {
  width: 80%;
  margin: auto;
  margin-top: 1rem;
}
.select_screen {
  color: var(--bg-color-1);
  border-bottom: 2px solid var(--bg-color-1);
}
.select_screen_box {
  margin-top: 1rem;
}
.description_box {
  line-height: 2rem;
}
.features_section {
  line-height: 2rem;
}
.parameter_section {
  width: 100%;
}
.parameter_section > div > span {
  flex: 1;
  line-height: 2rem;
}
.parameter_section > div {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #444444;
}
.images_container {
  padding: 2rem;
}
.images_container > img {
  width: 100%;
  height: 400px;
  object-fit: contain;
}
.images_container > img {
  width: 100%;
  object-fit: contain;
}
.images_container > div {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  width: 100%;
}
.images_container > div > div {
  flex: 1;
}
.images_container > div img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
}

@media screen and (min-width: 280px) and (max-width: 600px) {
  .products_details {
    flex-direction: column-reverse;
  }
}
@media screen and (min-width: 600px) and (max-width: 850px) {
  .product_detials_container {
    width: 90%;
  }
}
